@font-face {
  font-family: "AvertaDemo";
  src: url("../fonts/AvertaDemo-Regular.otf");
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf");
  font-style: light;
}
@font-face {
  font-family: "opensanssemibold";
  src: url("../fonts/OpenSans-Semibold.ttf");
  font-style: normal;
}
@font-face {
  font-family: "BellMT";
  src: url("../fonts/BellMT.ttf");
  font-style: normal;
}
@font-face {
  font-family: "BellMTBold";
  src: url("../fonts/BellMTBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "opensansbold";
  src: url("../fonts/OpenSans-ExtraBold.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Leaguespartanbold";
  src: url("../fonts/leaguespartan-bold.ttf");
  font-style: normal;
}
@font-face {
  font-family: "AvertaDemoBold";
  src: url("../fonts/FontsFree-Net-AvertaDemoPE-ExtraBold\ \(3\).ttf");
  font-style: bold;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../fonts/Montserrat-Bold.ttf");
  font-style: light;
}

@font-face {
  font-family: "Opensanslight";
  src: url("../fonts/OpenSans-Light.ttf");
  font-style: normal;
}
@font-face {
  font-family: "geometric";
  src: url("../fonts/geometric.ttf");
  font-style: normal;
}
@font-face {
  font-family: "segoesc";
  src: url("../fonts/segoesc.ttf");
  font-style: normal;
}

@font-face {
  font-family: "righteous";
  src: url("../fonts/Righteous-Regular.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Bebas";
  src: url("../fonts/Bebas.ttf");
  font-style: normal;
}
@font-face {
  font-family: "calistItallics";
  src: url("../fonts/CALISTI.TTF");
  font-style: italic;
}
@font-face {
  font-family: "IBMPlexSans";
  src: url("../fonts/IBMPlexSans-Regular.ttf");
}
@font-face {
  font-family: "IBMPlexSansBold";
  src: url("../fonts/IBMPlexSans-Bold.ttf");
}
@font-face {
  font-family: "IBMPlexSansLight";
  src: url("../fonts/IBMPlexSans-Light.ttf");
}
