:root {
  --border-width: 0.1em;
  --animation-speed: 0.5s;
  --color: #fff;
}
.border-animation {
  color: var(--color);
  position: relative;
  border: var(--border-width) solid transparent;
}
.border-animation .border-animation__inner {
  position: relative;
  cursor: pointer;
  padding: 0.2em;
  z-index: 1;
  border: solid var(--border-width) transparent;
}
.border-animation:before,
.border-animation:after {
  content: "";
  position: absolute;
  background: var(--color);
}
.border-animation-active:before,
.border-animation:focus:before,
.border-animation:hover:before {
  animation: beforeBorders var(--animation-speed) forwards ease-in-out;
}
.border-animation-active:after,
.border-animation:focus:after,
.border-animation:hover:after {
  animation: afterBorders var(--animation-speed) forwards ease-in-out;
}
.border-animation-active,
.border-animation:focus,
.border-animation:hover {
  animation: borderColors var(--animation-speed) steps(1) forwards;
  outline: none;
}
.border-animation-active .border-animation__inner,
.border-animation:focus .border-animation__inner,
.border-animation:hover .border-animation__inner {
  animation: background calc(var(--animation-speed) / 5 * 3) forwards
    ease-in-out;
  animation-delay: calc(var(--animation-speed) / 5 * 2);
}
@keyframes beforeBorders {
  0% {
    top: calc(var(--border-width) * -1);
    left: 50%;
    bottom: auto;
    right: auto;
    width: 0;
    height: var(--border-width);
  }
  33% {
    top: calc(var(--border-width) * -1);
    left: calc(var(--border-width) * -1);
    bottom: auto;
    right: auto;
    width: calc(var(--border-width) + 50%);
    height: var(--border-width);
  }
  66% {
    top: calc(var(--border-width) * -1);
    left: calc(var(--border-width) * -1);
    bottom: auto;
    right: auto;
    width: var(--border-width);
    height: calc((var(--border-width) * 2) + 100%);
  }
  100% {
    top: auto;
    left: calc(var(--border-width) * -1);
    bottom: calc(var(--border-width) * -1);
    right: auto;
    width: calc(var(--border-width) + 50%);
    height: calc((var(--border-width) * 2) + 100%);
  }
}
@keyframes afterBorders {
  0% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: 50%;
    width: 0;
    height: var(--border-width);
  }
  33% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: calc(var(--border-width) * -1);
    width: calc(var(--border-width) + 50%);
    height: var(--border-width);
  }
  66% {
    top: calc(var(--border-width) * -1);
    left: auto;
    bottom: auto;
    right: calc(var(--border-width) * -1);
    width: var(--border-width);
    height: calc((var(--border-width) * 2) + 100%);
  }
  100% {
    top: auto;
    left: auto;
    bottom: calc(var(--border-width) * -1);
    right: calc(var(--border-width) * -1);
    width: calc(var(--border-width) + 50%);
    height: calc((var(--border-width) * 2) + 100%);
  }
}
@keyframes borderColors {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  33% {
    border-top-color: var(--color);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  66% {
    border-top-color: var(--color);
    border-right-color: var(--color);
    border-bottom-color: transparent;
    border-left-color: var(--color);
  }
  100% {
    border-top-color: var(--color);
    border-right-color: var(--color);
    border-bottom-color: var(--color);
    border-left-color: var(--color);
  }
}
@keyframes background {
  to {
    background: rgb(3, 3, 3);
    text-shadow: 0 0.1em 0.1em #111;
  }
}

@keyframes a1 {
  90%,100%{flex-grow: 1}
}