@font-face {
  font-family: "opensansbold";
  src: url("../fonts/OpenSans-Bold.ttf");
  font-style: normal;
}
.footer-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 10rem;
}
.footer hr{
  margin: 0;
}
.footer {
  background-color: #020820;
  position: absolute;
  width: 100%;
}
.footer-text {
  padding-bottom: 2.5em;
  padding-top: 1em;
  margin: 0px;
  color: #fff;
  font-family: opensansbold;
  justify-content: center;
  text-align: center;
  font-size: 1.5em;
  height: 1em;
}

.follow-us {
  font-family: opensansbold;
}
.footer-main {
  display: flex;
  flex-direction: row;
  color: #fff;
  padding-left: 10rem;
  padding-right: 10rem;
  justify-content: space-between;
}

.footer-icons {
  text-align: right;
  margin-top: 2em;
}
.footer-icons img {
  padding-left: 1em;
  cursor: pointer;
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.footer-link {
  display: flex;
  flex-direction: row;
  width: 350px;
  justify-content: space-between;
}

.footer-link ul {
  list-style-type: none;
}
.footer-link ul li {
  font-size: 1em;
}
.footer-lower {
  color: #fff;
  margin-right: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.footer-lower-image img{
  width: 60px;
  height: 20px;
  object-fit: contain;
}
.footer-follow {
  cursor: pointer;
  font-family: "Opensanslight";
}
.footer-lower p {
  font-size: 0.85em;
  font-family: "Opensanslight";
}
.footer-left{
  margin-top: 1em;
}
.footer-left p {
  font-size: 1em;
}
.linkconnect {
  text-decoration: none;
  font-family: "Opensanslight";
  color: #fff;
  border-bottom: 3px solid transparent;
}
.linkconnect:after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  transition: width 0.7s ease;
}
.linkconnect:hover:after {
  width: 100%;
  background: #fff;
}
@media screen and (max-width: 600px) {
  ul {
    margin: 0;
    padding: 0;
  }
  .footer {
    padding: 1.2em;
    width: 90%;
  }
  .footer-text {
    text-align: start;
    font-size: 1.8em;
    font-weight: lighter;
  }
  .footer-main {
    padding: 0em;
    padding-top: 2em;
  }
  .footer-left {
    margin: 0em;
    width: 200px;
  }
  .footer-link {
    margin: 1em 0;
    width: 100%;
    justify-content: space-between;
  }
  .footer-left p {
    margin-left: 0.3em;
    font-size: 1em;
  }
  .footer-link ul li {
    font-size: 0.7em;
  }
  .footer-main {
    display: block;
  }
  .footer-icons {
    display: block;
    flex-direction: row;
    width: 100%;
    text-align: start;
  }
  .footer-icons img {
    padding: 0em;
    padding-right: 0.5em;
  }
  .footer-lower {
    display: flex;
    text-align: start;
    width: 100%;
    margin: 0em;
    justify-content: flex-start;
  }
  .footer-lower-image {
    align-self: center;
  }
}
