@font-face {
  font-family: 'AvertaDemo';
  src: url('../fonts/AvertaDemo-Regular.otf');
  font-style: normal;
}
@font-face {
  font-family: 'Opensanslight';
  src: url('../fonts/OpenSans-Light.ttf');
  font-style: normal;
}
.linkpadding{
  margin: 10px;
}
.dropdown-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 1em;
  text-align: start;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.dropdown-menu a {
  color:#2E324B !important;
  font-size: 0.85em;
  font-family: AvertaDemo;
}
.dropdown-menu li {
  cursor: pointer;
}
.dropdown-menu li:hover {
  cursor: pointer;
}
.dropdown-menu.clicked {
  display: none;
}
.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-weight: 500;
}
.dropdown-link:hover {
  /* transform: translateX(1%) translateY(1%); */
  transition: width 2s;
  font-weight: 600;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  font-weight: 600;
  animation-timing-function: ease-in;
}