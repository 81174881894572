li {
  list-style: none;
}

a {
  text-decoration: none;
}

.header {
  border-bottom: 1px solid #e2e8f0;
}

.navbar {
  top: 0;
  font-size: 1.2rem;
  position: fixed;
  z-index: 20;
  font-family: "AvertaDemo";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 7em;
  
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
}

.nav-item {
  margin-left: 2em;
}

.nav-link {
  font-size: 1.2rem;
  color: inherit;
}

.nav-link:after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  transition: width 0.7s ease;
}

.nav-link:hover:after {
  width: 100%;
  background: #fff;
}

@media only screen and (max-width: 768px) {
  .navbar{
    background-color: #2a3035 !important;
    
  }
  .nav-container{
    padding: 0 1.5em;
  }
  .nav-logo {
    font-weight: 500;
    margin: auto;
  }
  .nav-logo img {
    width: 200px;
  }
  .nav-menu {
    margin: auto;
    position: fixed;
    right: 0;
    transform: translateX(-100%);
    top: 5rem;
    flex-direction: column;
    background-color: #2a3035;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    color:#fff !important;
  }
  .nav-menu{
    justify-content: flex-start;
  }

  .nav-menu.active {
    /* left: 0px; */
    transform: translateX(0);
  }

  .nav-item {
    margin: 1rem;
  }
  .nav-link {
    font-size: 1rem;
  }
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }
}
